const all_phone_codes = []
const all_countries = [
  {
    "name": "Afghanistan",
    "code": "AF",
    "capital": "Kabul",
    "region": "AS",
    "currency": {
      "code": "AFN",
      "name": "Afghan afghani",
      "symbol": "؋"
    },
    "language": {
      "code": "ps",
      "name": "Pashto"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/afg.svg",
    "phone": "+93"
  },
  {
    "name": "Albania",
    "code": "AL",
    "capital": "Tirana",
    "region": "EU",
    "currency": {
      "code": "ALL",
      "name": "Albanian lek",
      "symbol": "L"
    },
    "language": {
      "code": "sq",
      "name": "Albanian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/alb.svg",
    "phone": "+355"
  },
  {
    "name": "Algeria",
    "code": "DZ",
    "capital": "Algiers",
    "region": "AF",
    "currency": {
      "code": "DZD",
      "name": "Algerian dinar",
      "symbol": "د.ج"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/dza.svg",
    "phone": "+213"
  },
  {
    "name": "American Samoa",
    "code": "AS",
    "capital": "Pago Pago",
    "region": "OC",
    "currency": {
      "code": "USD",
      "name": "United State Dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/asm.svg",
    "phone": "+1"
  },
  {
    "name": "Andorra",
    "code": "AD",
    "capital": "Andorra la Vella",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "ca",
      "name": "Catalan"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/and.svg",
    "phone": "+376"
  },
  {
    "name": "Angola",
    "code": "AO",
    "capital": "Luanda",
    "region": "AF",
    "currency": {
      "code": "AOA",
      "name": "Angolan kwanza",
      "symbol": "Kz"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ago.svg",
    "phone": "+244"
  },
  {
    "name": "Anguilla",
    "code": "AI",
    "capital": "The Valley",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/aia.svg",
    "phone": "+43"
  },
  {
    "name": "Antigua and Barbuda",
    "code": "AG",
    "capital": "Saint John's",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/atg.svg",
    "phone": "+1"
  },
  {
    "name": "Argentina",
    "code": "AR",
    "capital": "Buenos Aires",
    "region": "SA",
    "currency": {
      "code": "ARS",
      "name": "Argentine peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/arg.svg",
    "phone": "+54"
  },
  {
    "name": "Armenia",
    "code": "AM",
    "capital": "Yerevan",
    "region": "AS",
    "currency": {
      "code": "AMD",
      "name": "Armenian dram",
      "symbol": null
    },
    "language": {
      "code": "hy",
      "name": "Armenian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/arm.svg",
    "phone": "+374"
  },
  {
    "name": "Aruba",
    "code": "AW",
    "capital": "Oranjestad",
    "region": "SA",
    "currency": {
      "code": "AWG",
      "name": "Aruban florin",
      "symbol": "ƒ"
    },
    "language": {
      "code": "nl",
      "name": "Dutch"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/abw.svg",
    "phone": "+297"
  },
  {
    "name": "Australia",
    "code": "AU",
    "capital": "Canberra",
    "region": "OC",
    "currency": {
      "code": "AUD",
      "name": "Australian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/aus.svg",
    "phone": "+61"
  },
  {
    "name": "Azerbaijan",
    "code": "AZ",
    "capital": "Baku",
    "region": "AS",
    "currency": {
      "code": "AZN",
      "name": "Azerbaijani manat",
      "symbol": null
    },
    "language": {
      "code": "az",
      "name": "Azerbaijani"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/aze.svg",
    "phone": "+994"
  },
  {
    "name": "Bahamas",
    "code": "BS",
    "capital": "Nassau",
    "region": "NA",
    "currency": {
      "code": "BSD",
      "name": "Bahamian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bhs.svg",
    "phone": "+1"
  },
  {
    "name": "Bahrain",
    "code": "BH",
    "capital": "Manama",
    "region": "AS",
    "currency": {
      "code": "BHD",
      "name": "Bahraini dinar",
      "symbol": ".د.ب"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bhr.svg",
    "phone": "+973"
  },
  {
    "name": "Bangladesh",
    "code": "BD",
    "capital": "Dhaka",
    "region": "AS",
    "currency": {
      "code": "BDT",
      "name": "Bangladeshi taka",
      "symbol": "৳"
    },
    "language": {
      "code": "bn",
      "name": "Bengali"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bgd.svg",
    "phone": "+880"
  },
  {
    "name": "Barbados",
    "code": "BB",
    "capital": "Bridgetown",
    "region": "NA",
    "currency": {
      "code": "BBD",
      "name": "Barbadian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/brb.svg",
    "phone": "+1"
  },
  {
    "name": "Belarus",
    "code": "BY",
    "capital": "Minsk",
    "region": "EU",
    "currency": {
      "code": "BYN",
      "name": "New Belarusian ruble",
      "symbol": "Br"
    },
    "language": {
      "code": "be",
      "name": "Belarusian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/blr.svg",
    "phone": "+375"
  },
  {
    "name": "Belgium",
    "code": "BE",
    "capital": "Brussels",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "nl",
      "name": "Dutch"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bel.svg",
    "phone": "+32"
  },
  {
    "name": "Belize",
    "code": "BZ",
    "capital": "Belmopan",
    "region": "NA",
    "currency": {
      "code": "BZD",
      "name": "Belize dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/blz.svg",
    "phone": "+501"
  },
  {
    "name": "Benin",
    "code": "BJ",
    "capital": "Porto-Novo",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ben.svg",
    "phone": "+229"
  },
  {
    "name": "Bermuda",
    "code": "BM",
    "capital": "Hamilton",
    "region": "NA",
    "currency": {
      "code": "BMD",
      "name": "Bermudian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bmu.svg",
    "phone": "+1"
  },
  {
    "name": "Bhutan",
    "code": "BT",
    "capital": "Thimphu",
    "region": "AS",
    "currency": {
      "code": "BTN",
      "name": "Bhutanese ngultrum",
      "symbol": "Nu."
    },
    "language": {
      "code": "dz",
      "name": "Dzongkha"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/btn.svg",
    "phone": "+975"
  },
  {
    "name": "Bolivia (Plurinational State of)",
    "code": "BO",
    "capital": "Sucre",
    "region": "SA",
    "currency": {
      "code": "BOB",
      "name": "Bolivian boliviano",
      "symbol": "Bs."
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bol.svg",
    "phone": "+591"
  },
  {
    "name": "Bosnia and Herzegovina",
    "code": "BA",
    "capital": "Sarajevo",
    "region": "EU",
    "currency": {
      "code": "BAM",
      "name": "Bosnia and Herzegovina convertible mark",
      "symbol": null
    },
    "language": {
      "code": "bs",
      "name": "Bosnian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bih.svg",
    "phone": "+387"
  },
  {
    "name": "Botswana",
    "code": "BW",
    "capital": "Gaborone",
    "region": "AF",
    "currency": {
      "code": "BWP",
      "name": "Botswana pula",
      "symbol": "P"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bwa.svg",
    "phone": "+267"
  },
  {
    "name": "Brazil",
    "code": "BR",
    "capital": "Brasília",
    "region": "SA",
    "currency": {
      "code": "BRL",
      "name": "Brazilian real",
      "symbol": "R$"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bra.svg",
    "phone": "+55"
  },
  {
    "name": "British Indian Ocean Territory",
    "code": "IO",
    "capital": "Diego Garcia",
    "region": "AF",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/iot.svg",
    "phone": "+246"
  },
  {
    "name": "Virgin Islands (British)",
    "code": "VG",
    "capital": "Road Town",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/vgb.svg",
    "phone": "+1"
  },
  {
    "name": "Virgin Islands (U.S.)",
    "code": "VI",
    "capital": "Charlotte Amalie",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/vir.svg",
    "phone": "+1"
  },
  {
    "name": "Brunei Darussalam",
    "code": "BN",
    "capital": "Bandar Seri Begawan",
    "region": "AS",
    "currency": {
      "code": "BND",
      "name": "Brunei dollar",
      "symbol": "$"
    },
    "language": {
      "code": "ms",
      "name": "Malay"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/brn.svg",
    "phone": "+673"
  },
  {
    "name": "Bulgaria",
    "code": "BG",
    "capital": "Sofia",
    "region": "EU",
    "currency": {
      "code": "BGN",
      "name": "Bulgarian lev",
      "symbol": "лв"
    },
    "language": {
      "code": "bg",
      "name": "Bulgarian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bgr.svg",
    "phone": "+359"
  },
  {
    "name": "Burkina Faso",
    "code": "BF",
    "capital": "Ouagadougou",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bfa.svg",
    "phone": "+226"
  },
  {
    "name": "Burundi",
    "code": "BI",
    "capital": "Bujumbura",
    "region": "AF",
    "currency": {
      "code": "BIF",
      "name": "Burundian franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/bdi.svg",
    "phone": "+257"
  },
  {
    "name": "Cambodia",
    "code": "KH",
    "capital": "Phnom Penh",
    "region": "AS",
    "currency": {
      "code": "KHR",
      "name": "Cambodian riel",
      "symbol": "៛"
    },
    "language": {
      "code": "km",
      "name": "Khmer"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/khm.svg",
    "phone": "+855"
  },
  {
    "name": "Cameroon",
    "code": "CM",
    "capital": "Yaoundé",
    "region": "AF",
    "currency": {
      "code": "XAF",
      "name": "Central African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cmr.svg",
    "phone": "+237"
  },
  {
    "name": "Canada",
    "code": "CA",
    "capital": "Ottawa",
    "region": "NA",
    "currency": {
      "code": "CAD",
      "name": "Canadian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/can.svg",
    "phone": "+1"
  },
  {
    "name": "Cabo Verde",
    "code": "CV",
    "capital": "Praia",
    "region": "AF",
    "currency": {
      "code": "CVE",
      "name": "Cape Verdean escudo",
      "symbol": "Esc"
    },
    "language": {
      "code": "pt",
      "iso639_2": "por",
      "name": "Portuguese",
      "nativeName": "Português"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cpv.svg",
    "phone": "+238"
  },
  {
    "name": "Cayman Islands",
    "code": "KY",
    "capital": "George Town",
    "region": "NA",
    "demonym": "Caymanian",
    "currency": {
      "code": "KYD",
      "name": "Cayman Islands dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cym.svg",
    "phone": "+1"
  },
  {
    "name": "Central African Republic",
    "code": "CF",
    "capital": "Bangui",
    "region": "AF",
    "currency": {
      "code": "XAF",
      "name": "Central African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/caf.svg",
    "phone": "+236"
  },
  {
    "name": "Central African Republic",
    "code": "CF",
    "capital": "Bangui",
    "region": "AF",
    "currency": {
      "code": "XAF",
      "name": "Central African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/caf.svg",
    "phone": "+236"
  },
  {
    "name": "Chile",
    "code": "CL",
    "capital": "Santiago",
    "region": "SA",
    "currency": {
      "code": "CLP",
      "name": "Chilean peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "iso639_2": "spa",
      "name": "Spanish",
      "nativeName": "Español"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/chl.svg",
    "phone": "+56"
  },
  {
    "name": "China",
    "code": "CN",
    "capital": "Beijing",
    "region": "AS",
    "currency": {
      "code": "CNY",
      "name": "Chinese yuan",
      "symbol": "¥"
    },
    "language": {
      "code": "zh",
      "name": "Chinese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/chn.svg",
    "phone": "+86"
  },
  {
    "name": "Colombia",
    "code": "CO",
    "capital": "Bogotá",
    "region": "SA",
    "currency": {
      "code": "COP",
      "name": "Colombian peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/col.svg",
    "phone": "+57"
  },
  {
    "name": "Comoros",
    "code": "KM",
    "capital": "Moroni",
    "region": "AF",
    "currency": {
      "code": "KMF",
      "name": "Comorian franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/com.svg",
    "phone": "+269"
  },
  {
    "name": "Congo",
    "code": "CG",
    "capital": "Brazzaville",
    "region": "AF",
    "currency": {
      "code": "XAF",
      "name": "Central African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cog.svg",
    "phone": "+242"
  },
  {
    "name": "Congo (Democratic Republic of the)",
    "code": "CD",
    "capital": "Kinshasa",
    "region": "AF",
    "currency": {
      "code": "CDF",
      "name": "Congolese franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cod.svg",
    "phone": "+243"
  },
  {
    "name": "Cook Islands",
    "code": "CK",
    "capital": "Avarua",
    "region": "OC",
    "currency": {
      "code": "NZD",
      "name": "New Zealand dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cok.svg",
    "phone": "+682"
  },
  {
    "name": "Costa Rica",
    "code": "CR",
    "capital": "San José",
    "region": "NA",
    "currency": {
      "code": "CRC",
      "name": "Costa Rican colón",
      "symbol": "₡"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cri.svg",
    "phone": "+506"
  },
  {
    "name": "Croatia",
    "code": "HR",
    "capital": "Zagreb",
    "region": "EU",
    "currency": {
      "code": "HRK",
      "name": "Croatian kuna",
      "symbol": "kn"
    },
    "language": {
      "code": "hr",
      "name": "Croatian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/hrv.svg",
    "phone": "+385"
  },
  {
    "name": "Cuba",
    "code": "CU",
    "capital": "Havana",
    "region": "NA",
    "currency": {
      "code": "CUC",
      "name": "Cuban convertible peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cub.svg",
    "phone": "+53"
  },
  {
    "name": "Cuba",
    "code": "CU",
    "capital": "Havana",
    "region": "NA",
    "currency": {
      "code": "CUC",
      "name": "Cuban convertible peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cub.svg",
    "phone": "+53"
  },
  {
    "name": "Cyprus",
    "code": "CY",
    "capital": "Nicosia",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "tr",
      "name": "Turkish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cyp.svg",
    "phone": "+357"
  },
  {
    "name": "Czech Republic",
    "code": "CZ",
    "capital": "Prague",
    "region": "EU",
    "currency": {
      "code": "CZK",
      "name": "Czech koruna",
      "symbol": "Kč"
    },
    "language": {
      "code": "cs",
      "name": "Czech"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/cze.svg",
    "phone": "+420"
  },
  {
    "name": "Denmark",
    "code": "DK",
    "capital": "Copenhagen",
    "region": "EU",
    "currency": {
      "code": "DKK",
      "name": "Danish krone",
      "symbol": "kr"
    },
    "language": {
      "code": "da",
      "name": "Danish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/dnk.svg",
    "phone": "+45"
  },
  {
    "name": "Djibouti",
    "code": "DJ",
    "capital": "Djibouti",
    "region": "AF",
    "currency": {
      "code": "DJF",
      "name": "Djiboutian franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/dji.svg",
    "phone": "+253"
  },
  {
    "name": "Dominica",
    "code": "DM",
    "capital": "Roseau",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/dma.svg",
    "phone": "+1"
  },
  {
    "name": "Dominican Republic",
    "code": "DO",
    "capital": "Santo Domingo",
    "region": "NA",
    "currency": {
      "code": "DOP",
      "name": "Dominican peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/dom.svg",
    "phone": "+1"
  },
  {
    "name": "Ecuador",
    "code": "EC",
    "capital": "Quito",
    "region": "SA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ecu.svg",
    "phone": "+593"
  },
  {
    "name": "Egypt",
    "code": "EG",
    "capital": "Cairo",
    "region": "AF",
    "currency": {
      "code": "EGP",
      "name": "Egyptian pound",
      "symbol": "£"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/egy.svg",
    "phone": "+20"
  },
  {
    "name": "El Salvador",
    "code": "SV",
    "capital": "San Salvador",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/slv.svg",
    "phone": "+503"
  },
  {
    "name": "Equatorial Guinea",
    "code": "GQ",
    "capital": "Malabo",
    "region": "AF",
    "currency": {
      "code": "XAF",
      "name": "Central African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "es",
      "iso639_2": "spa",
      "name": "Spanish",
      "nativeName": "Español"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gnq.svg",
    "phone": "+240"
  },
  {
    "name": "Eritrea",
    "code": "ER",
    "capital": "Asmara",
    "region": "AF",
    "currency": {
      "code": "ERN",
      "name": "Eritrean nakfa",
      "symbol": "Nfk"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/eri.svg",
    "phone": "+291"
  },
  {
    "name": "Estonia",
    "code": "EE",
    "capital": "Tallinn",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "et",
      "name": "Estonian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/est.svg",
    "phone": "+372"
  },
  {
    "name": "Ethiopia",
    "code": "ET",
    "capital": "Addis Ababa",
    "region": "AF",
    "currency": {
      "code": "ETB",
      "name": "Ethiopian birr",
      "symbol": "Br"
    },
    "language": {
      "code": "am",
      "name": "Amharic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/eth.svg",
    "phone": "+251"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "code": "FK",
    "capital": "Stanley",
    "region": "SA",
    "currency": {
      "code": "FKP",
      "name": "Falkland Islands pound",
      "symbol": "£"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/flk.svg",
    "phone": "+500"
  },
  {
    "name": "Faroe Islands",
    "code": "FO",
    "capital": "Tórshavn",
    "region": "EU",
    "currency": {
      "code": "DKK",
      "name": "Danish krone",
      "symbol": "kr"
    },
    "language": {
      "code": "fo",
      "name": "Faroese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/fro.svg",
    "phone": "+298"
  },
  {
    "name": "Fiji",
    "code": "FJ",
    "capital": "Suva",
    "region": "OC",
    "currency": {
      "code": "FJD",
      "name": "Fijian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/fji.svg",
    "phone": "+679"
  },
  {
    "name": "Finland",
    "code": "FI",
    "capital": "Helsinki",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fi",
      "iso639_2": "fin",
      "name": "Finnish",
      "nativeName": "suomi"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/fin.svg",
    "phone": "+358"
  },
  {
    "name": "France",
    "code": "FR",
    "capital": "Paris",
    "region": "EU",
    "demonym": "French",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/fra.svg",
    "phone": "+33"
  },
  {
    "name": "French Guiana",
    "code": "GF",
    "capital": "Cayenne",
    "region": "SA",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/guf.svg",
    "phone": "+594"
  },
  {
    "name": "French Polynesia",
    "code": "PF",
    "capital": "Papeetē",
    "region": "OC",
    "currency": {
      "code": "XPF",
      "name": "CFP franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pyf.svg",
    "phone": "+689"
  },
  {
    "name": "Gabon",
    "code": "GA",
    "capital": "Libreville",
    "region": "AF",
    "currency": {
      "code": "XAF",
      "name": "Central African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gab.svg",
    "phone": "+241"
  },
  {
    "name": "Gambia",
    "code": "GM",
    "capital": "Banjul",
    "region": "AF",
    "currency": {
      "code": "GMD",
      "name": "Gambian dalasi",
      "symbol": "D"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gmb.svg",
    "phone": "+220"
  },
  {
    "name": "Georgia",
    "code": "GE",
    "capital": "Tbilisi",
    "region": "AS",
    "currency": {
      "code": "GEL",
      "name": "Georgian Lari",
      "symbol": "ლ"
    },
    "language": {
      "code": "ka",
      "name": "Georgian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/geo.svg",
    "phone": "+995"
  },
  {
    "name": "Germany",
    "code": "DE",
    "capital": "Berlin",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "de",
      "name": "German"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/deu.svg",
    "phone": "+49"
  },
  {
    "name": "Ghana",
    "code": "GH",
    "capital": "Accra",
    "region": "AF",
    "currency": {
      "code": "GHS",
      "name": "Ghanaian cedi",
      "symbol": "₵"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gha.svg",
    "phone": "+233"
  },
  {
    "name": "Gibraltar",
    "code": "GI",
    "capital": "Gibraltar",
    "region": "EU",
    "currency": {
      "code": "GIP",
      "name": "Gibraltar pound",
      "symbol": "£"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gib.svg",
    "phone": "+350"
  },
  {
    "name": "Greece",
    "code": "GR",
    "capital": "Athens",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "el",
      "name": "Greek (modern)"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/grc.svg",
    "phone": "+30"
  },
  {
    "name": "Greenland",
    "code": "GL",
    "capital": "Nuuk",
    "region": "NA",
    "currency": {
      "code": "DKK",
      "name": "Danish krone",
      "symbol": "kr"
    },
    "language": {
      "code": "kl",
      "name": "Kalaallisut"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/grl.svg",
    "phone": "+299"
  },
  {
    "name": "Grenada",
    "code": "GD",
    "capital": "St. George's",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/grd.svg",
    "phone": "+1"
  },
  {
    "name": "Guadeloupe",
    "code": "GP",
    "capital": "Basse-Terre",
    "region": "NA",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/glp.svg",
    "phone": "+590"
  },
  {
    "name": "Guam",
    "code": "GU",
    "capital": "Hagåtña",
    "region": "OC",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gum.svg",
    "phone": "+1"
  },
  {
    "name": "Guatemala",
    "code": "GT",
    "capital": "Guatemala City",
    "region": "NA",
    "currency": {
      "code": "GTQ",
      "name": "Guatemalan quetzal",
      "symbol": "Q"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gtm.svg",
    "phone": "+502"
  },
  {
    "name": "Guinea",
    "code": "GN",
    "capital": "Conakry",
    "region": "AF",
    "currency": {
      "code": "GNF",
      "name": "Guinean franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gin.svg",
    "phone": "+224"
  },
  {
    "name": "Guinea-Bissau",
    "code": "GW",
    "capital": "Bissau",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gnb.svg",
    "phone": "+245"
  },
  {
    "name": "Guyana",
    "code": "GY",
    "capital": "Georgetown",
    "region": "SA",
    "currency": {
      "code": "GYD",
      "name": "Guyanese dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/guy.svg",
    "phone": "+592"
  },
  {
    "name": "Haiti",
    "code": "HT",
    "capital": "Port-au-Prince",
    "region": "Americas",
    "currency": {
      "code": "HTG",
      "name": "Haitian gourde",
      "symbol": "G"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/hti.svg",
    "phone": "+509"
  },
  {
    "name": "Holy See",
    "code": "VA",
    "capital": "Rome",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/vat.svg",
    "phone": "+39"
  },
  {
    "name": "Honduras",
    "code": "HN",
    "capital": "Tegucigalpa",
    "region": "NA",
    "currency": {
      "code": "HNL",
      "name": "Honduran lempira",
      "symbol": "L"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/hnd.svg",
    "phone": "+504"
  },
  {
    "name": "Hong Kong",
    "code": "HK",
    "capital": "City of Victoria",
    "region": "AS",
    "currency": {
      "code": "HKD",
      "name": "Hong Kong dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/hkg.svg",
    "phone": "+852"
  },
  {
    "name": "Hungary",
    "code": "HU",
    "capital": "Budapest",
    "region": "EU",
    "currency": {
      "code": "HUF",
      "name": "Hungarian forint",
      "symbol": "Ft"
    },
    "language": {
      "code": "hu",
      "name": "Hungarian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/hun.svg",
    "phone": "+36"
  },
  {
    "name": "Iceland",
    "code": "IS",
    "capital": "Reykjavík",
    "region": "EU",
    "currency": {
      "code": "ISK",
      "name": "Icelandic króna",
      "symbol": "kr"
    },
    "language": {
      "code": "is",
      "name": "Icelandic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/isl.svg",
    "phone": "+354"
  },
  {
    "name": "India",
    "code": "IN",
    "capital": "New Delhi",
    "region": "AS",
    "currency": {
      "code": "INR",
      "name": "Indian rupee",
      "symbol": "₹"
    },
    "language": {
      "code": "hi",
      "name": "Hindi"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ind.svg",
    "phone": "+91"
  },
  {
    "name": "Indonesia",
    "code": "ID",
    "capital": "Jakarta",
    "region": "AS",
    "currency": {
      "code": "IDR",
      "name": "Indonesian rupiah",
      "symbol": "Rp"
    },
    "language": {
      "code": "id",
      "name": "Indonesian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/idn.svg",
    "phone": "+62"
  },
  {
    "name": "Côte d'Ivoire",
    "code": "CI",
    "capital": "Yamoussoukro",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/civ.svg",
    "phone": "+225"
  },
  {
    "name": "Iran (Islamic Republic of)",
    "code": "IR",
    "capital": "Tehran",
    "region": "AS",
    "currency": {
      "code": "IRR",
      "name": "Iranian rial",
      "symbol": "﷼"
    },
    "language": {
      "code": "fa",
      "name": "Persian (Farsi)"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/irn.svg",
    "phone": "+98"
  },
  {
    "name": "Iraq",
    "code": "IQ",
    "capital": "Baghdad",
    "region": "AS",
    "currency": {
      "code": "IQD",
      "name": "Iraqi dinar",
      "symbol": "ع.د"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/irq.svg",
    "phone": "+964"
  },
  {
    "name": "Ireland",
    "code": "IE",
    "capital": "Dublin",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "ga",
      "name": "Irish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/irl.svg",
    "phone": "+353"
  },
  {
    "name": "Israel",
    "code": "IL",
    "capital": "Jerusalem",
    "region": "AS",
    "currency": {
      "code": "ILS",
      "name": "Israeli new shekel",
      "symbol": "₪"
    },
    "language": {
      "code": "he",
      "name": "Hebrew (modern)"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/isr.svg",
    "phone": "+972"
  },
  {
    "name": "Italy",
    "code": "IT",
    "capital": "Rome",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "it",
      "name": "Italian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ita.svg",
    "phone": "+39"
  },
  {
    "name": "Jamaica",
    "code": "JM",
    "capital": "Kingston",
    "region": "NA",
    "currency": {
      "code": "JMD",
      "name": "Jamaican dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/jam.svg",
    "phone": "+1"
  },
  {
    "name": "Japan",
    "code": "JP",
    "capital": "Tokyo",
    "region": "AS",
    "currency": {
      "code": "JPY",
      "name": "Japanese yen",
      "symbol": "¥"
    },
    "language": {
      "code": "ja",
      "name": "Japanese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/jpn.svg",
    "phone": "+81"
  },
  {
    "name": "Jordan",
    "code": "JO",
    "capital": "Amman",
    "region": "AS",
    "currency": {
      "code": "JOD",
      "name": "Jordanian dinar",
      "symbol": "د.ا"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/jor.svg",
    "phone": "+962"
  },
  {
    "name": "Kazakhstan",
    "code": "KZ",
    "capital": "Astana",
    "region": "AS",
    "currency": {
      "code": "KZT",
      "name": "Kazakhstani tenge",
      "symbol": null
    },
    "language": {
      "code": "kk",
      "name": "Kazakh"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kaz.svg",
    "phone": "+7"
  },
  {
    "name": "Kenya",
    "code": "KE",
    "capital": "Nairobi",
    "region": "AF",
    "currency": {
      "code": "KES",
      "name": "Kenyan shilling",
      "symbol": "Sh"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ken.svg",
    "phone": "+254"
  },
  {
    "name": "Kiribati",
    "code": "KI",
    "capital": "South Tarawa",
    "region": "OC",
    "currency": {
      "code": "AUD",
      "name": "Australian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kir.svg",
    "phone": "+686"
  },
  {
    "name": "Kuwait",
    "code": "KW",
    "capital": "Kuwait City",
    "region": "AS",
    "currency": {
      "code": "KWD",
      "name": "Kuwaiti dinar",
      "symbol": "د.ك"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kwt.svg",
    "phone": "+965"
  },
  {
    "name": "Kyrgyzstan",
    "code": "KG",
    "capital": "Bishkek",
    "region": "AS",
    "currency": {
      "code": "KGS",
      "name": "Kyrgyzstani som",
      "symbol": "с"
    },
    "language": {
      "code": "ky",
      "name": "Kyrgyz"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kgz.svg",
    "phone": "+996"
  },
  {
    "name": "Lao People's Democratic Republic",
    "code": "LA",
    "capital": "Vientiane",
    "region": "AS",
    "currency": {
      "code": "LAK",
      "name": "Lao kip",
      "symbol": "₭"
    },
    "language": {
      "code": "lo",
      "name": "Lao"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lao.svg",
    "phone": "+856"
  },
  {
    "name": "Latvia",
    "code": "LV",
    "capital": "Riga",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "lv",
      "name": "Latvian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lva.svg",
    "phone": "+371"
  },
  {
    "name": "Lebanon",
    "code": "LB",
    "capital": "Beirut",
    "region": "AS",
    "currency": {
      "code": "LBP",
      "name": "Lebanese pound",
      "symbol": "ل.ل"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lbn.svg",
    "phone": "+961"
  },
  {
    "name": "Lesotho",
    "code": "LS",
    "capital": "Maseru",
    "region": "AF",
    "currency": {
      "code": "LSL",
      "name": "Lesotho loti",
      "symbol": "L"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lso.svg",
    "phone": "+266"
  },
  {
    "name": "Liberia",
    "code": "LR",
    "capital": "Monrovia",
    "region": "AF",
    "currency": {
      "code": "LRD",
      "name": "Liberian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lbr.svg",
    "phone": "+231"
  },
  {
    "name": "Libya",
    "code": "LY",
    "capital": "Tripoli",
    "region": "AF",
    "currency": {
      "code": "LYD",
      "name": "Libyan dinar",
      "symbol": "ل.د"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lby.svg",
    "phone": "+218"
  },
  {
    "name": "Liechtenstein",
    "code": "LI",
    "capital": "Vaduz",
    "region": "EU",
    "currency": {
      "code": "CHF",
      "name": "Swiss franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "de",
      "name": "German"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lie.svg",
    "phone": "+423"
  },
  {
    "name": "Lithuania",
    "code": "LT",
    "capital": "Vilnius",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "lt",
      "name": "Lithuanian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ltu.svg",
    "phone": "+370"
  },
  {
    "name": "Luxembourg",
    "code": "LU",
    "capital": "Luxembourg",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lux.svg",
    "phone": "+352"
  },
  {
    "name": "Macao",
    "code": "MO",
    "capital": "",
    "region": "AS",
    "currency": {
      "code": "MOP",
      "name": "Macanese pataca",
      "symbol": "P"
    },
    "language": {
      "code": "zh",
      "name": "Chinese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mac.svg",
    "phone": "+853"
  },
  {
    "name": "Macedonia (the former Yugoslav Republic of)",
    "code": "MK",
    "capital": "Skopje",
    "region": "EU",
    "currency": {
      "code": "MKD",
      "name": "Macedonian denar",
      "symbol": "ден"
    },
    "language": {
      "code": "mk",
      "name": "Macedonian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mkd.svg",
    "phone": "+389"
  },
  {
    "name": "Madagascar",
    "code": "MG",
    "capital": "Antananarivo",
    "region": "AF",
    "currency": {
      "code": "MGA",
      "name": "Malagasy ariary",
      "symbol": "Ar"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mdg.svg",
    "phone": "+261"
  },
  {
    "name": "Malawi",
    "code": "MW",
    "capital": "Lilongwe",
    "region": "AF",
    "currency": {
      "code": "MWK",
      "name": "Malawian kwacha",
      "symbol": "MK"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mwi.svg",
    "phone": "+265"
  },
  {
    "name": "Malaysia",
    "code": "MY",
    "capital": "Kuala Lumpur",
    "region": "AS",
    "currency": {
      "code": "MYR",
      "name": "Malaysian ringgit",
      "symbol": "RM"
    },
    "language": {
      "code": null,
      "name": "Malaysian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mys.svg",
    "phone": "+60"
  },
  {
    "name": "Maldives",
    "code": "MV",
    "capital": "Malé",
    "region": "AS",
    "currency": {
      "code": "MVR",
      "name": "Maldivian rufiyaa",
      "symbol": ".ރ"
    },
    "language": {
      "code": "dv",
      "name": "Divehi"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mdv.svg",
    "phone": "+960"
  },
  {
    "name": "Mali",
    "code": "ML",
    "capital": "Bamako",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mli.svg",
    "phone": "+223"
  },
  {
    "name": "Malta",
    "code": "MT",
    "capital": "Valletta",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "mt",
      "name": "Maltese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mlt.svg",
    "phone": "+356"
  },
  {
    "name": "Marshall Islands",
    "code": "MH",
    "capital": "Majuro",
    "region": "OC",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mhl.svg",
    "phone": "+692"
  },
  {
    "name": "Martinique",
    "code": "MQ",
    "capital": "Fort-de-France",
    "region": "Americas",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mtq.svg",
    "phone": "+596"
  },
  {
    "name": "Mauritania",
    "code": "MR",
    "capital": "Nouakchott",
    "region": "AF",
    "currency": {
      "code": "MRO",
      "name": "Mauritanian ouguiya",
      "symbol": "UM"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mrt.svg",
    "phone": "+222"
  },
  {
    "name": "Mauritius",
    "code": "MU",
    "capital": "Port Louis",
    "region": "AF",
    "currency": {
      "code": "MUR",
      "name": "Mauritian rupee",
      "symbol": "₨"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mus.svg",
    "phone": "+230"
  },
  {
    "name": "Mayotte",
    "code": "YT",
    "capital": "Mamoudzou",
    "region": "AF",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/myt.svg",
    "phone": "+262"
  },
  {
    "name": "Mexico",
    "code": "MX",
    "capital": "Mexico City",
    "region": "NA",
    "currency": {
      "code": "MXN",
      "name": "Mexican peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mex.svg",
    "phone": "+52"
  },
  {
    "name": "Micronesia (Federated States of)",
    "code": "FM",
    "capital": "Palikir",
    "region": "OC",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/fsm.svg",
    "phone": "+691"
  },
  {
    "name": "Moldova (Republic of)",
    "code": "MD",
    "capital": "Chișinău",
    "region": "EU",
    "currency": {
      "code": "MDL",
      "name": "Moldovan leu",
      "symbol": "L"
    },
    "language": {
      "code": "ro",
      "name": "Romanian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mda.svg",
    "phone": "+373"
  },
  {
    "name": "Monaco",
    "code": "MC",
    "capital": "Monaco",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mco.svg",
    "phone": "+377"
  },
  {
    "name": "Mongolia",
    "code": "MN",
    "capital": "Ulan Bator",
    "region": "AS",
    "currency": {
      "code": "MNT",
      "name": "Mongolian tögrög",
      "symbol": "₮"
    },
    "language": {
      "code": "mn",
      "name": "Mongolian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mng.svg",
    "phone": "+976"
  },
  {
    "name": "Montenegro",
    "code": "ME",
    "capital": "Podgorica",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "sr",
      "name": "Serbian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mne.svg",
    "phone": "+382"
  },
  {
    "name": "Montserrat",
    "code": "MS",
    "capital": "Plymouth",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/msr.svg",
    "phone": "+1"
  },
  {
    "name": "Morocco",
    "code": "MA",
    "capital": "Rabat",
    "region": "AF",
    "currency": {
      "code": "MAD",
      "name": "Moroccan dirham",
      "symbol": "د.م."
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mar.svg",
    "phone": "+212"
  },
  {
    "name": "Mozambique",
    "code": "MZ",
    "capital": "Maputo",
    "region": "AF",
    "currency": {
      "code": "MZN",
      "name": "Mozambican metical",
      "symbol": "MT"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/moz.svg",
    "phone": "+258"
  },
  {
    "name": "Myanmar",
    "code": "MM",
    "capital": "Naypyidaw",
    "region": "AS",
    "currency": {
      "code": "MMK",
      "name": "Burmese kyat",
      "symbol": "Ks"
    },
    "language": {
      "code": "my",
      "name": "Burmese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mmr.svg",
    "phone": "+95"
  },
  {
    "name": "Namibia",
    "code": "NA",
    "capital": "Windhoek",
    "region": "AF",
    "currency": {
      "code": "NAD",
      "name": "Namibian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nam.svg",
    "phone": "+264"
  },
  {
    "name": "Nauru",
    "code": "NR",
    "capital": "Yaren",
    "region": "OC",
    "currency": {
      "code": "AUD",
      "name": "Australian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nru.svg",
    "phone": "+674"
  },
  {
    "name": "Nepal",
    "code": "NP",
    "capital": "Kathmandu",
    "region": "AS",
    "currency": {
      "code": "NPR",
      "name": "Nepalese rupee",
      "symbol": "₨"
    },
    "language": {
      "code": "ne",
      "name": "Nepali"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/npl.svg",
    "phone": "+977"
  },
  {
    "name": "Netherlands",
    "code": "NL",
    "capital": "Amsterdam",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "nl",
      "name": "Dutch"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nld.svg",
    "phone": "+31"
  },
  {
    "name": "New Caledonia",
    "code": "NC",
    "capital": "Nouméa",
    "region": "OC",
    "currency": {
      "code": "XPF",
      "name": "CFP franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ncl.svg",
    "phone": "+687"
  },
  {
    "name": "New Zealand",
    "code": "NZ",
    "capital": "Wellington",
    "region": "OC",
    "currency": {
      "code": "NZD",
      "name": "New Zealand dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nzl.svg",
    "phone": "+64"
  },
  {
    "name": "Nicaragua",
    "code": "NI",
    "capital": "Managua",
    "region": "NA",
    "currency": {
      "code": "NIO",
      "name": "Nicaraguan córdoba",
      "symbol": "C$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nic.svg",
    "phone": "+505"
  },
  {
    "name": "Niger",
    "code": "NE",
    "capital": "Niamey",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ner.svg",
    "phone": "+227"
  },
  {
    "name": "Nigeria",
    "code": "NG",
    "capital": "Abuja",
    "region": "AF",
    "currency": {
      "code": "NGN",
      "name": "Nigerian naira",
      "symbol": "₦"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nga.svg",
    "phone": "+234"
  },
  {
    "name": "Niue",
    "code": "NU",
    "capital": "Alofi",
    "region": "OC",
    "currency": {
      "code": "NZD",
      "name": "New Zealand dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/niu.svg",
    "phone": "+683"
  },
  {
    "name": "Norfolk Island",
    "code": "NF",
    "capital": "Kingston",
    "region": "OC",
    "currency": {
      "code": "AUD",
      "name": "Australian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nfk.svg",
    "phone": "+672"
  },
  {
    "name": "Korea (Democratic People's Republic of)",
    "code": "KP",
    "capital": "Pyongyang",
    "region": "AS",
    "currency": {
      "code": "KPW",
      "name": "North Korean won",
      "symbol": "₩"
    },
    "language": {
      "code": "ko",
      "name": "Korean"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/prk.svg",
    "phone": "+850"
  },
  {
    "name": "Northern Mariana Islands",
    "code": "MP",
    "capital": "Saipan",
    "region": "OC",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/mnp.svg",
    "phone": "+1"
  },
  {
    "name": "Norway",
    "code": "NO",
    "capital": "Oslo",
    "region": "EU",
    "currency": {
      "code": "NOK",
      "name": "Norwegian krone",
      "symbol": "kr"
    },
    "language": {
      "code": "no",
      "name": "Norwegian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/nor.svg",
    "phone": "+47"
  },
  {
    "name": "Oman",
    "code": "OM",
    "capital": "Muscat",
    "region": "AS",
    "currency": {
      "code": "OMR",
      "name": "Omani rial",
      "symbol": "ر.ع."
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/omn.svg",
    "phone": "+968"
  },
  {
    "name": "Pakistan",
    "code": "PK",
    "capital": "Islamabad",
    "region": "AS",
    "currency": {
      "code": "PKR",
      "name": "Pakistani rupee",
      "symbol": "₨"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pak.svg",
    "phone": "+92"
  },
  {
    "name": "Palau",
    "code": "PW",
    "capital": "Ngerulmud",
    "region": "OC",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/plw.svg",
    "phone": "+680"
  },
  {
    "name": "Palestine, State of",
    "code": "PS",
    "capital": "Ramallah",
    "region": "AS",
    "currency": {
      "code": "ILS",
      "name": "Israeli new sheqel",
      "symbol": "₪"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pse.svg",
    "phone": "+970"
  },
  {
    "name": "Panama",
    "code": "PA",
    "capital": "Panama City",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pan.svg",
    "phone": "+507"
  },
  {
    "name": "Papua New Guinea",
    "code": "PG",
    "capital": "Port Moresby",
    "region": "OC",
    "currency": {
      "code": "PGK",
      "name": "Papua New Guinean kina",
      "symbol": "K"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/png.svg",
    "phone": "+675"
  },
  {
    "name": "Paraguay",
    "code": "PY",
    "capital": "Asunción",
    "region": "SA",
    "currency": {
      "code": "PYG",
      "name": "Paraguayan guaraní",
      "symbol": "₲"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pry.svg",
    "phone": "+595"
  },
  {
    "name": "Peru",
    "code": "PE",
    "capital": "Lima",
    "region": "SA",
    "currency": {
      "code": "PEN",
      "name": "Peruvian sol",
      "symbol": "S/."
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/per.svg",
    "phone": "+51"
  },
  {
    "name": "Philippines",
    "code": "PH",
    "capital": "Manila",
    "region": "AS",
    "currency": {
      "code": "PHP",
      "name": "Philippine peso",
      "symbol": "₱"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/phl.svg",
    "phone": "+63"
  },
  {
    "name": "Poland",
    "code": "PL",
    "capital": "Warsaw",
    "region": "EU",
    "currency": {
      "code": "PLN",
      "name": "Polish złoty",
      "symbol": "zł"
    },
    "language": {
      "code": "pl",
      "name": "Polish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pol.svg",
    "phone": "+48"
  },
  {
    "name": "Portugal",
    "code": "PT",
    "capital": "Lisbon",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/prt.svg",
    "phone": "+351"
  },
  {
    "name": "Puerto Rico",
    "code": "PR",
    "capital": "San Juan",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/pri.svg",
    "phone": "+1"
  },
  {
    "name": "Qatar",
    "code": "QA",
    "capital": "Doha",
    "region": "AS",
    "currency": {
      "code": "QAR",
      "name": "Qatari riyal",
      "symbol": "ر.ق"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/qat.svg",
    "phone": "+974"
  },
  {
    "name": "Republic of Kosovo",
    "code": "XK",
    "capital": "Pristina",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "sq",
      "name": "Albanian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kos.svg",
    "phone": "+381"
  },
  {
    "name": "Réunion",
    "code": "RE",
    "capital": "Saint-Denis",
    "region": "AF",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/reu.svg",
    "phone": "+262"
  },
  {
    "name": "Romania",
    "code": "RO",
    "capital": "Bucharest",
    "region": "EU",
    "currency": {
      "code": "RON",
      "name": "Romanian leu",
      "symbol": "lei"
    },
    "language": {
      "code": "ro",
      "name": "Romanian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/rou.svg",
    "phone": "+40"
  },
  {
    "name": "Russian Federation",
    "code": "RU",
    "capital": "Moscow",
    "region": "EU",
    "currency": {
      "code": "RUB",
      "name": "Russian ruble",
      "symbol": "₽"
    },
    "language": {
      "code": "ru",
      "name": "Russian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/rus.svg",
    "phone": "+7"
  },
  {
    "name": "Rwanda",
    "code": "RW",
    "capital": "Kigali",
    "region": "AF",
    "currency": {
      "code": "RWF",
      "name": "Rwandan franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "rw",
      "name": "Kinyarwanda"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/rwa.svg",
    "phone": "+250"
  },
  {
    "name": "Saint Barthélemy",
    "code": "BL",
    "capital": "Gustavia",
    "region": "NA",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/blm.svg",
    "phone": "+590"
  },
  {
    "name": "Saint Helena, Ascension and Tristan da Cunha",
    "code": "SH",
    "capital": "Jamestown",
    "region": "AF",
    "currency": {
      "code": "SHP",
      "name": "Saint Helena pound",
      "symbol": "£"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/shn.svg",
    "phone": "+290"
  },
  {
    "name": "Saint Kitts and Nevis",
    "code": "KN",
    "capital": "Basseterre",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kna.svg",
    "phone": "+1"
  },
  {
    "name": "Saint Lucia",
    "code": "LC",
    "capital": "Castries",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lca.svg",
    "phone": "+1"
  },
  {
    "name": "Saint Martin (French part)",
    "code": "MF",
    "capital": "Marigot",
    "region": "NA",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/maf.svg",
    "phone": "+590"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "code": "PM",
    "capital": "Saint-Pierre",
    "region": "NA",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/spm.svg",
    "phone": "+508"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "VC",
    "capital": "Kingstown",
    "region": "NA",
    "currency": {
      "code": "XCD",
      "name": "East Caribbean dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/vct.svg",
    "phone": "+1"
  },
  {
    "name": "Samoa",
    "code": "WS",
    "capital": "Apia",
    "region": "OC",
    "currency": {
      "code": "WST",
      "name": "Samoan tālā",
      "symbol": "T"
    },
    "language": {
      "code": "sm",
      "name": "Samoan"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/wsm.svg",
    "phone": "+685"
  },
  {
    "name": "San Marino",
    "code": "SM",
    "capital": "City of San Marino",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "it",
      "name": "Italian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/smr.svg",
    "phone": "+378"
  },
  {
    "name": "Sao Tome and Principe",
    "code": "ST",
    "capital": "São Tomé",
    "region": "AF",
    "currency": {
      "code": "STD",
      "name": "São Tomé and Príncipe dobra",
      "symbol": "Db"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/stp.svg",
    "phone": "+239"
  },
  {
    "name": "Saudi Arabia",
    "code": "SA",
    "capital": "Riyadh",
    "region": "AS",
    "currency": {
      "code": "SAR",
      "name": "Saudi riyal",
      "symbol": "ر.س"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sau.svg",
    "phone": "+966"
  },
  {
    "name": "Senegal",
    "code": "SN",
    "capital": "Dakar",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sen.svg",
    "phone": "+221"
  },
  {
    "name": "Serbia",
    "code": "RS",
    "capital": "Belgrade",
    "region": "EU",
    "currency": {
      "code": "RSD",
      "name": "Serbian dinar",
      "symbol": "дин."
    },
    "language": {
      "code": "sr",
      "name": "Serbian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/srb.svg",
    "phone": "+381"
  },
  {
    "name": "Seychelles",
    "code": "SC",
    "capital": "Victoria",
    "region": "AF",
    "currency": {
      "code": "SCR",
      "name": "Seychellois rupee",
      "symbol": "₨"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/syc.svg",
    "phone": "+248"
  },
  {
    "name": "Sierra Leone",
    "code": "SL",
    "capital": "Freetown",
    "region": "AF",
    "currency": {
      "code": "SLL",
      "name": "Sierra Leonean leone",
      "symbol": "Le"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sle.svg",
    "phone": "+232"
  },
  {
    "name": "Singapore",
    "code": "SG",
    "capital": "Singapore",
    "region": "AS",
    "currency": {
      "code": "SGD",
      "name": "Singapore dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sgp.svg",
    "phone": "+65"
  },
  {
    "name": "Singapore",
    "code": "SG",
    "capital": "Singapore",
    "region": "AS",
    "currency": {
      "code": "SGD",
      "name": "Singapore dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sgp.svg",
    "phone": "+65"
  },
  {
    "name": "Slovakia",
    "code": "SK",
    "capital": "Bratislava",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "sk",
      "name": "Slovak"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/svk.svg",
    "phone": "+421"
  },
  {
    "name": "Slovenia",
    "code": "SI",
    "capital": "Ljubljana",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "sl",
      "name": "Slovene"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/svn.svg",
    "phone": "+386"
  },
  {
    "name": "Solomon Islands",
    "code": "SB",
    "capital": "Honiara",
    "region": "OC",
    "currency": {
      "code": "SBD",
      "name": "Solomon Islands dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/slb.svg",
    "phone": "+677"
  },
  {
    "name": "Somalia",
    "code": "SO",
    "capital": "Mogadishu",
    "region": "AF",
    "currency": {
      "code": "SOS",
      "name": "Somali shilling",
      "symbol": "Sh"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/som.svg",
    "phone": "+252"
  },
  {
    "name": "South Africa",
    "code": "ZA",
    "capital": "Pretoria",
    "region": "AF",
    "currency": {
      "code": "ZAR",
      "name": "South African rand",
      "symbol": "R"
    },
    "language": {
      "code": "en",
      "iso639_2": "eng",
      "name": "English",
      "nativeName": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/zaf.svg",
    "phone": "+27"
  },
  {
    "name": "Korea (Republic of)",
    "code": "KR",
    "capital": "Seoul",
    "region": "AS",
    "currency": {
      "code": "KRW",
      "name": "South Korean won",
      "symbol": "₩"
    },
    "language": {
      "code": "ko",
      "name": "Korean"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/kor.svg",
    "phone": "+82"
  },
  {
    "name": "Spain",
    "code": "ES",
    "capital": "Madrid",
    "region": "EU",
    "currency": {
      "code": "EUR",
      "name": "Euro",
      "symbol": "€"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/esp.svg",
    "phone": "+34"
  },
  {
    "name": "Sri Lanka",
    "code": "LK",
    "capital": "Colombo",
    "region": "AS",
    "currency": {
      "code": "LKR",
      "name": "Sri Lankan rupee",
      "symbol": "Rs"
    },
    "language": {
      "code": "si",
      "iso639_2": "sin",
      "name": "Sinhalese",
      "nativeName": "සිංහල"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/lka.svg",
    "phone": "+94"
  },
  {
    "name": "Sudan",
    "code": "SD",
    "capital": "Khartoum",
    "region": "AF",
    "currency": {
      "code": "SDG",
      "name": "Sudanese pound",
      "symbol": "ج.س."
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sdn.svg",
    "phone": "+249"
  },
  {
    "name": "Suriname",
    "code": "SR",
    "capital": "Paramaribo",
    "region": "SA",
    "currency": {
      "code": "SRD",
      "name": "Surinamese dollar",
      "symbol": "$"
    },
    "language": {
      "code": "nl",
      "name": "Dutch"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/sur.svg",
    "phone": "+597"
  },
  {
    "name": "Swaziland",
    "code": "SZ",
    "capital": "Lobamba",
    "region": "AF",
    "currency": {
      "code": "SZL",
      "name": "Swazi lilangeni",
      "symbol": "L"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/swz.svg",
    "phone": "+268"
  },
  {
    "name": "Sweden",
    "code": "SE",
    "capital": "Stockholm",
    "region": "EU",
    "currency": {
      "code": "SEK",
      "name": "Swedish krona",
      "symbol": "kr"
    },
    "language": {
      "code": "sv",
      "name": "Swedish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/swe.svg",
    "phone": "+46"
  },
  {
    "name": "Switzerland",
    "code": "CH",
    "capital": "Bern",
    "region": "EU",
    "currency": {
      "code": "CHF",
      "name": "Swiss franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "de",
      "name": "German"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/che.svg",
    "phone": "+41"
  },
  {
    "name": "Syrian Arab Republic",
    "code": "SY",
    "capital": "Damascus",
    "region": "AS",
    "currency": {
      "code": "SYP",
      "name": "Syrian pound",
      "symbol": "£"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/syr.svg",
    "phone": "+963"
  },
  {
    "name": "Taiwan",
    "code": "TW",
    "capital": "Taipei",
    "region": "AS",
    "currency": {
      "code": "TWD",
      "name": "New Taiwan dollar",
      "symbol": "$"
    },
    "language": {
      "code": "zh",
      "name": "Chinese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/twn.svg",
    "phone": "+886"
  },
  {
    "name": "Tajikistan",
    "code": "TJ",
    "capital": "Dushanbe",
    "region": "AS",
    "currency": {
      "code": "TJS",
      "name": "Tajikistani somoni",
      "symbol": "ЅМ"
    },
    "language": {
      "code": "tg",
      "name": "Tajik"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tjk.svg",
    "phone": "+992"
  },
  {
    "name": "Tanzania, United Republic of",
    "code": "TZ",
    "capital": "Dodoma",
    "region": "AF",
    "currency": {
      "code": "TZS",
      "name": "Tanzanian shilling",
      "symbol": "Sh"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tza.svg",
    "phone": "+255"
  },
  {
    "name": "Thailand",
    "code": "TH",
    "capital": "Bangkok",
    "region": "AS",
    "currency": {
      "code": "THB",
      "name": "Thai baht",
      "symbol": "฿"
    },
    "language": {
      "code": "th",
      "name": "Thai"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tha.svg",
    "phone": "+66"
  },
  {
    "name": "Timor-Leste",
    "code": "TL",
    "capital": "Dili",
    "region": "AS",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "pt",
      "name": "Portuguese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tls.svg",
    "phone": "+670"
  },
  {
    "name": "Togo",
    "code": "TG",
    "capital": "Lomé",
    "region": "AF",
    "currency": {
      "code": "XOF",
      "name": "West African CFA franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tgo.svg",
    "phone": "+228"
  },
  {
    "name": "Tokelau",
    "code": "TK",
    "capital": "Fakaofo",
    "region": "OC",
    "currency": {
      "code": "NZD",
      "name": "New Zealand dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tkl.svg",
    "phone": "+690"
  },
  {
    "name": "Tonga",
    "code": "TO",
    "capital": "Nuku'alofa",
    "region": "OC",
    "currency": {
      "code": "TOP",
      "name": "Tongan paʻanga",
      "symbol": "T$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ton.svg",
    "phone": "+676"
  },
  {
    "name": "Trinidad and Tobago",
    "code": "TT",
    "capital": "Port of Spain",
    "region": "SA",
    "currency": {
      "code": "TTD",
      "name": "Trinidad and Tobago dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tto.svg",
    "phone": "+1"
  },
  {
    "name": "Tunisia",
    "code": "TN",
    "capital": "Tunis",
    "region": "AF",
    "currency": {
      "code": "TND",
      "name": "Tunisian dinar",
      "symbol": "د.ت"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tun.svg",
    "phone": "+216"
  },
  {
    "name": "Turkey",
    "code": "TR",
    "capital": "Ankara",
    "region": "AS",
    "currency": {
      "code": "TRY",
      "name": "Turkish lira",
      "symbol": null
    },
    "language": {
      "code": "tr",
      "name": "Turkish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tur.svg",
    "phone": "+90"
  },
  {
    "name": "Turkmenistan",
    "code": "TM",
    "capital": "Ashgabat",
    "region": "AS",
    "currency": {
      "code": "TMT",
      "name": "Turkmenistan manat",
      "symbol": "m"
    },
    "language": {
      "code": "tk",
      "name": "Turkmen"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tkm.svg",
    "phone": "+993"
  },
  {
    "name": "Turks and Caicos Islands",
    "code": "TC",
    "capital": "Cockburn Town",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tca.svg",
    "phone": "+1"
  },
  {
    "name": "Tuvalu",
    "code": "TV",
    "capital": "Funafuti",
    "region": "OC",
    "currency": {
      "code": "AUD",
      "name": "Australian dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/tuv.svg",
    "phone": "+688"
  },
  {
    "name": "Uganda",
    "code": "UG",
    "capital": "Kampala",
    "region": "AF",
    "currency": {
      "code": "UGX",
      "name": "Ugandan shilling",
      "symbol": "Sh"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/uga.svg",
    "phone": "+256"
  },
  {
    "name": "Ukraine",
    "code": "UA",
    "capital": "Kiev",
    "region": "EU",
    "currency": {
      "code": "UAH",
      "name": "Ukrainian hryvnia",
      "symbol": "₴"
    },
    "language": {
      "code": "uk",
      "name": "Ukrainian"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ukr.svg",
    "phone": "+380"
  },
  {
    "name": "United Arab Emirates",
    "code": "AE",
    "capital": "Abu Dhabi",
    "region": "AS",
    "currency": {
      "code": "AED",
      "name": "United Arab Emirates dirham",
      "symbol": "د.إ"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/are.svg",
    "phone": "+971"
  },
  {
    "name": "United Kingdom of Great Britain and Northern Ireland",
    "code": "GB",
    "capital": "London",
    "region": "EU",
    "currency": {
      "code": "GBP",
      "name": "British pound",
      "symbol": "£"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/gbr.svg",
    "phone": "+44"
  },
  {
    "name": "United States of America",
    "code": "US",
    "capital": "Washington, D.C.",
    "region": "NA",
    "currency": {
      "code": "USD",
      "name": "United States dollar",
      "symbol": "$"
    },
    "language": {
      "code": "en",
      "iso639_2": "eng",
      "name": "English",
      "nativeName": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/usa.svg",
    "phone": "+1"
  },
  {
    "name": "Uruguay",
    "code": "UY",
    "capital": "Montevideo",
    "region": "SA",
    "currency": {
      "code": "UYU",
      "name": "Uruguayan peso",
      "symbol": "$"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ury.svg",
    "phone": "+598"
  },
  {
    "name": "Uzbekistan",
    "code": "UZ",
    "capital": "Tashkent",
    "region": "AS",
    "currency": {
      "code": "UZS",
      "name": "Uzbekistani so'm",
      "symbol": null
    },
    "language": {
      "code": "uz",
      "name": "Uzbek"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/uzb.svg",
    "phone": "+998"
  },
  {
    "name": "Vanuatu",
    "code": "VU",
    "capital": "Port Vila",
    "region": "OC",
    "currency": {
      "code": "VUV",
      "name": "Vanuatu vatu",
      "symbol": "Vt"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/vut.svg",
    "phone": "+678"
  },
  {
    "name": "Venezuela (Bolivarian Republic of)",
    "code": "VE",
    "capital": "Caracas",
    "region": "SA",
    "currency": {
      "code": "VEF",
      "name": "Venezuelan bolívar",
      "symbol": "Bs F"
    },
    "language": {
      "code": "es",
      "name": "Spanish"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/ven.svg",
    "phone": "+58"
  },
  {
    "name": "Viet Nam",
    "code": "VN",
    "capital": "Hanoi",
    "region": "AS",
    "currency": {
      "code": "VND",
      "name": "Vietnamese đồng",
      "symbol": "₫"
    },
    "language": {
      "code": "vi",
      "name": "Vietnamese"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/vnm.svg",
    "phone": "+84"
  },
  {
    "name": "Wallis and Futuna",
    "code": "WF",
    "capital": "Mata-Utu",
    "region": "OC",
    "currency": {
      "code": "XPF",
      "name": "CFP franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/wlf.svg",
    "phone": "+681"
  },
  {
    "name": "Wallis and Futuna",
    "code": "WF",
    "capital": "Mata-Utu",
    "region": "OC",
    "currency": {
      "code": "XPF",
      "name": "CFP franc",
      "symbol": "Fr"
    },
    "language": {
      "code": "fr",
      "name": "French"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/wlf.svg",
    "phone": "+681"
  },
  {
    "name": "Yemen",
    "code": "YE",
    "capital": "Sana'a",
    "region": "AS",
    "currency": {
      "code": "YER",
      "name": "Yemeni rial",
      "symbol": "﷼"
    },
    "language": {
      "code": "ar",
      "name": "Arabic"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/yem.svg",
    "phone": "+967"
  },
  {
    "name": "Zambia",
    "code": "ZM",
    "capital": "Lusaka",
    "region": "AF",
    "currency": {
      "code": "ZMW",
      "name": "Zambian kwacha",
      "symbol": "ZK"
    },
    "language": {
      "code": "en",
      "name": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/zmb.svg",
    "phone": "+260"
  },
  {
    "name": "Zimbabwe",
    "code": "ZW",
    "capital": "Harare",
    "region": "AF",
    "currency": {
      "code": "BWP",
      "name": "Botswana pula",
      "symbol": "P"
    },
    "language": {
      "code": "en",
      "iso639_2": "eng",
      "name": "English",
      "nativeName": "English"
    },
    "flag": "https://raw.githubusercontent.com/linssen/country-flag-icons/master/images/svg/zwe.svg",
    "phone": "+263"
  }
];
export default all_countries;
